import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: '登录',
		component: () => import('../views/login/login.vue')
	},
	{
		path: '/login',
		name: '登录',
		component: () => import('../views/login/login.vue')
	},
	{
		path: '/home',
		name: '首页',
		redirect: '/accounts',
		meta: {
			requireAuth: true,
		},
		component: () => import("../views/index/home.vue"),
		children: [
			{
				path: '/accounts',
				name: 'accounts',
				component: () => import("../views/accounts/accounts.vue"),
			},
			{
				path: '/add_accounts',
				name: 'add_accounts',
				component: () => import("../views/accounts/add_accounts.vue"),
			},
			{
				path: '/statistics',
				name: 'statistics',
				component: () => import("../views/accounts/statistics.vue"),
			}
		]
	},
	{
		path: '/:pathMatch(.*)*',
		component: () => import("../views/notFound.vue"),
	},
]

const router = new VueRouter({
	routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
	// 判断是否有token，用户是否登录
	let token = localStorage.getItem('token')
	// 如果有token或是去登录页，放行
	if (token || to.path==='/login') {
		next()
	// 如果没有token强制跳转到登录页
	} else {
		next('/login')
	}
})


export default router
