<template>
    <div class="toast" v-show="isShow">
        {{ message }}
    </div>
</template>
  
<script>

export default {
    name: 'Toast',
    data() {
        return {
            message: '',
            isShow: false // 默认为不显示弹窗
        }
    },
    methods: {
        // 定义一个方法，这样子可以通过.show的方式来调养这个方法,方法接收两个参数，弹窗内容和消失时间
        show(message, time = 1500) {
            // 如果调用了这个方法，就将isShow变成true，也就是显示弹窗
            this.isShow = true
            // 显示弹窗信息
            this.message = message
            // 做一个定时器，定时器的作用就是让弹窗显示一段时间后消失，也就是将isShow 和message在前面的存储的状态还原最初
            setTimeout(() => {
                this.isShow = false
                this.message = ''
                // 传入的显示时间
            }, time)
        }
    },
}

</script>
<style  scoped>
.toast {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    z-index: 1000000;
}
</style>