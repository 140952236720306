import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import  request  from './api/request'
// 引入toast
import toast from './api/toast.js'

Vue.prototype.$request = request
// Vue.prototype.$baseURL = baseURL

Vue.config.productionTip = false
// 安装toast插件
Vue.use(toast)
Vue.use(ElementUI);
// Vue.use(Element, { size: 'norma', zIndex: 3000 });



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
